import { observable } from 'mobx';

export class Token {
  @observable
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  jti: string;
  created: number;
  authorities: Array<string> = [];

  constructor(fullJson?: any) {
    if (fullJson) {
      this.access_token = fullJson.access_token;
      this.refresh_token = fullJson.refresh_token;
      this.token_type = fullJson.token_type;
      this.expires_in = fullJson.expires_in;
      this.scope = fullJson.scope;
      this.jti = fullJson.jti;
      this.created = fullJson.created;
      this.authorities = fullJson.authorities;
    }
  }

  hasAuthority(authority: string): boolean {
    return this.authorities.findIndex((a) => a === authority) !== -1;
  }

  get expired(): boolean {
    if (this.created > 0) {
      const now = Date.now();
      const expireMs = (this.expires_in - 10) * 1000; // subtract 10 seconds to be on the safe side
      const expireTimestamp = this.created + expireMs;
      return now > expireTimestamp;
    }
    return true;
  }
}
