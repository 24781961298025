import React, { useCallback } from 'react';
import { Layout, Menu, Button, Popconfirm } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useRootStore } from '../../Store/useRootStore';
import { useAuth0 } from '@auth0/auth0-react';

const { Header } = Layout;
const { SubMenu } = Menu;

export type AppHeaderProps = {};

export const AppHeader: React.FC<AppHeaderProps> = observer(() => {
  const navigate = useNavigate();
  const { authentication } = useRootStore();
  const { logout } = useAuth0();

  const handleLogout = useCallback(() => {
    logout({
      logoutParams: {
        returnTo: `https://admin.kinastic.com/auth`,
      },
    });
  }, [logout]);

  const handleLogoClick = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  return (
    <Header style={{ background: '#fff', padding: '0 24px', boxShadow: '0 2px 8px #f0f1f2' }}>
      <div style={{ maxWidth: 1200, margin: '0 auto', display: 'flex', alignItems: 'center' }}>
        <div style={{ fontSize: 20, fontWeight: 600, cursor: 'pointer', marginRight: 40 }} onClick={handleLogoClick}>
          Admin
        </div>
        <Menu mode="horizontal" style={{ flex: 1 }}>
          {authentication.hasAnyRole(['ROLE_SALES', 'ROLE_SUPPORT']) && (
            <SubMenu key="infrastructure" title="Infrastructure">
              <Menu.Item key="companies">
                <Link to="/infrastructure/gym">Companies</Link>
              </Menu.Item>
              <Menu.Item key="customers">
                <Link to="/infrastructure/customers">Customers</Link>
              </Menu.Item>
            </SubMenu>
          )}
          <SubMenu key="metadata" title="Meta Data">
            <Menu.Item key="activities">
              <Link to="/metadata/activity">Activities</Link>
            </Menu.Item>
            {authentication.isGodAdmin && (
              <Menu.Item key="exercises">
                <Link to="/metadata/exercise">Exercises</Link>
              </Menu.Item>
            )}
            <Menu.Item key="bodyparts">
              <Link to="/metadata/bodypart">Body Parts</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="equipment-types">
              <Link to="/metadata/equipment/type">Equipment Types</Link>
            </Menu.Item>
            <Menu.Divider />
            {authentication.isOneAboveAll && (
              <>
                <Menu.Item key="medias">
                  <Link to="/metadata/media">Medias</Link>
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            <Menu.Item key="translations">
              <Link to="/metadata/translations">Translations</Link>
            </Menu.Item>
            <Menu.Item key="dailytips">
              <Link to="/metadata/dailyTips">DailyTips</Link>
            </Menu.Item>
          </SubMenu>
          {authentication.hasAnyRole(['ROLE_SALES', 'ROLE_SUPPORT']) && (
            <Menu.Item key="athletes">
              <Link to="/athlete">Athletes</Link>
            </Menu.Item>
          )}
          {authentication.isGodAdmin && (
            <SubMenu key="engagement" title="Engagement">
              <Menu.Item key="earn-model-templates">
                <Link to="/engagement/earn-model-templates">Earn Model Templates</Link>
              </Menu.Item>
              <Menu.Item key="route-challenges">
                <Link to="/engagement/route-challenges">Route Challenges</Link>
              </Menu.Item>
            </SubMenu>
          )}
          <SubMenu key="content" title="Content">
            <Menu.Item key="templates-header" disabled style={{ fontWeight: 'bold' }}>
              Fitness Program
            </Menu.Item>
            <Menu.Item key="fitness-program-templates">
              <Link to="/coach/program-template">Fitness Program Templates</Link>
            </Menu.Item>
            <Menu.Item key="workout-templates">
              <Link to="/coach/workout-template">Workout Templates</Link>
            </Menu.Item>
            {authentication.isOneAboveAll && (
              <>
                <Menu.Item key="advanced-header" disabled style={{ fontWeight: 'bold' }}>
                  Advanced
                </Menu.Item>
                <Menu.Item key="workout-script">
                  <Link to="/coach/workout-script">Workout Pipelines</Link>
                </Menu.Item>
                <Menu.Item key="workout-modules">
                  <Link to="/coach/workout-modules">Workout Modules</Link>
                </Menu.Item>
              </>
            )}
            {authentication.hasAnyRole(['SALES', 'SUPPORT']) && (
              <Menu.Item key="fitness-program">
                <Link to="/coach/fitness-program">Fitness Programs</Link>
              </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.Item key="extras-header" disabled style={{ fontWeight: 'bold' }}>
              Mindfulness Program
            </Menu.Item>
            <Menu.Item key="mindfulness">
              <Link to="/coach/mindfulness-template">Mindfulness Program Templates</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="diet-header" disabled style={{ fontWeight: 'bold' }}>
              Diet
            </Menu.Item>
            <Menu.Item key="recipes">
              <Link to="/coach/diet/recipes">Recipes</Link>
            </Menu.Item>
            <Menu.Item key="ingredients">
              <Link to="/coach/diet/ingredients">Ingredients</Link>
            </Menu.Item>
            <Menu.Item key="recipe-generator">
              <Link to="/coach/diet/recipes/generator">Recipe Generator</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="explore-header" disabled style={{ fontWeight: 'bold' }}>
              Content
            </Menu.Item>
            <Menu.Item key="explore-tab">
              <Link to="/coach/explore">Explore Tab</Link>
            </Menu.Item>
            <Menu.Item key="explore-entries">
              <Link to="/coach/explore/entries">Explore Entries</Link>
            </Menu.Item>
            <Menu.Item key="explore-faq">
              <Link to="/coach/explore/faq">Explore FAQ</Link>
            </Menu.Item>
            <Menu.Item key="highlight-entries">
              <Link to="/coach/content/highlight">Highlight Entries</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="misc-header" disabled style={{ fontWeight: 'bold' }}>
              Miscellaneous
            </Menu.Item>
            <Menu.Item key="tts">
              <Link to="/coach/tts">Text To Speech</Link>
            </Menu.Item>
            {authentication.isGodAdmin && (
              <Menu.Item key="events">
                <Link to="/coach/events">Events / Schedule</Link>
              </Menu.Item>
            )}
            <Menu.Divider />
            {authentication.isGodAdmin && (
              <Menu.Item key="knowledge-base">
                <Link to="/coach/knowledge-base">Knowledge Base</Link>
              </Menu.Item>
            )}
          </SubMenu>
          {authentication.hasAnyRole(['SALES']) && (
            <SubMenu key="analytics" title="Analytics">
              <Menu.Item key="general-analytics">
                <Link to="/analytics">General Analytics</Link>
              </Menu.Item>
              <Menu.Item key="company-dashboard">
                <Link to="/analytics/company-dashboard">Company Dashboard</Link>
              </Menu.Item>
              <Menu.Item key="studio-analytics">
                <Link to="/analytics/studio-analytics">Studio Analytics</Link>
              </Menu.Item>
            </SubMenu>
          )}
          {authentication.isAdmin && (
            <SubMenu key="studio" title="Studio">
              <Menu.Item key="campaign-calendar">
                <Link to="/customer-success/campaign-calendar">Campaign Calendar</Link>
              </Menu.Item>
              <Menu.Item key="file-storage">
                <Link to="/customer-success/file-storage">File Storage</Link>
              </Menu.Item>
            </SubMenu>
          )}
          {authentication.isOneAboveAll && (
            <SubMenu key="security" title="Security">
              <Menu.Item key="oauth-clients">
                <Link to="/security/oauth">OAuth Clients</Link>
              </Menu.Item>
            </SubMenu>
          )}
          <SubMenu key="tools" title="Tools">
            <Menu.Item key="dynamic-link-builder">
              <Link to="/tools/dynamic-link-builder">Dynamic Link Builder</Link>
            </Menu.Item>
            <Menu.Item key="qr-code-builder">
              <Link to="/tools/qr-code-builder">QR Code Builder</Link>
            </Menu.Item>
            <Menu.Item key="deepl">
              <Link to="/tools/deepl">DeepL</Link>
            </Menu.Item>
            {authentication.isOneAboveAll && (
              <Menu.Item key="exporter">
                <Link to="/tools/exporter">Exporter</Link>
              </Menu.Item>
            )}
          </SubMenu>
        </Menu>
        <div style={{ marginLeft: 16, display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: 16, fontSize: 14 }}>{authentication.athlete?.user.email}</span>
          <Popconfirm title="Are you sure you want to logout?" onConfirm={handleLogout} okText="Yes" cancelText="No">
            <Button type="primary" icon={<LogoutOutlined />}>
              Logout
            </Button>
          </Popconfirm>
        </div>
      </div>
    </Header>
  );
});
