/**
 * Created by neo on 05.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { SingleColRow } from '../../Components/SingleColRow';
import { Button, message, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import dayjs from '../../Utils/dayjs';

function getFilenameWithoutExtension(file) {
  const fullFileName = file.name;
  const lastIndex = fullFileName.lastIndexOf('.');

  // If there is no extension, return the full name
  if (lastIndex === -1) return fullFileName;

  // Otherwise, return the name without the extension
  return fullFileName.substring(0, lastIndex);
}

function getFileExtension(file) {
  const fullFileName = file.name;
  const lastIndex = fullFileName.lastIndexOf('.');

  // If there is no extension or '.' is the last character, return an empty string
  if (lastIndex === -1 || lastIndex === fullFileName.length - 1) return '';

  // Otherwise, return the extension
  return fullFileName.substring(lastIndex + 1);
}

export type DeeplToolScreenProps = {};

export const DeeplToolScreen: React.FC<DeeplToolScreenProps> = observer((props) => {
  const [uploading, setUploading] = useState(false);
  const [targetLang, setTargetLang] = useState('EN');

  const checkDownloadStatus = (file, { document_id, document_key }) => {
    const finalFilename = `${getFilenameWithoutExtension(file)}-${targetLang}-${dayjs().format(
      'YYYY-MM-DD_HH-mm-ss',
    )}.${getFileExtension(file)}`
    return HttpBackend.post(`/messaging/translation/deepl/document/${document_id}`, { document_key }).then(({ status }) => {
      if (status === 'done') {
        HttpBackend.postRaw(
          `/messaging/translation/deepl/document/${document_id}/result?filename=${finalFilename}`,
          {
            document_key,
          },
          { responseType: 'blob' },
        ).then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));

          // Create a temporary anchor to trigger download
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', finalFilename); // Set the file name for download
          document.body.appendChild(fileLink);

          // Programmatically click the link to trigger the download
          fileLink.click();

          // Clean up by removing the temporary link and revoking the blob URL
          fileLink.remove();
          window.URL.revokeObjectURL(fileURL);
        });
      } else if (status === 'error') {
        message.error('Failed to translate document');
      } else {
        setTimeout(() => checkDownloadStatus(file, { document_id, document_key }), 1000);
      }
    });
  };

  const beforeUpload = (file: RcFile) => {
    setUploading(true);
    const data = new FormData();
    data.append('file', file);

    HttpBackend.upload(`/messaging/translation/deepl/document?target_lang=${targetLang}`, data, {
      timeout: 3600000,
    })
      .then((data) => checkDownloadStatus(file, data))
      .catch(() => message.error('Failed to upload document'))
      .finally(() => setUploading(false));

    return false;
  };

  const handleChangeLang = (value) => setTargetLang(value);

  return (
    <Container>
      <SingleColRow>
        <h1>DeepL Translator</h1>
      </SingleColRow>
      <Row>
        <Col>
          <Upload beforeUpload={beforeUpload}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Col>
        <Col>
          <Select value={targetLang} onChange={handleChangeLang}>
            <Select.Option value="EN">English</Select.Option>
            <Select.Option value="DE">German</Select.Option>
            <Select.Option value="FR">French</Select.Option>
            <Select.Option value="IT">Italian</Select.Option>
            <Select.Option value="ES">Spanish</Select.Option>
          </Select>
        </Col>
      </Row>
    </Container>
  );
});
