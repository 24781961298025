/**
 *
 */
import * as React from 'react';
import { Routes, Route } from 'react-router';
import { LoadingContainer } from './Components/LoadingContainer';
import { AuthScreen } from './View/Auth/AuthScreen';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRootStore } from './Store/useRootStore';
import { observer } from 'mobx-react';
import { MainLayout } from './View/Layout/MainLayout';
import { DashboardScreen } from './View/Dashboard/DashboardScreen';
import { GymListScreen } from './View/Infrastructure/Gym/GymListScreen';
import { GymViewScreen } from './View/Infrastructure/Gym/GymViewScreen/GymViewScreen';
import { CustomersListScreen } from './View/Infrastructure/Customers/CustomersListScreen';
import { MetaDataRouter } from './View/MetaData/MetaDataRouter';
import { CoachRouter } from './View/Coach/CoachRouter';
import { ActivityLogListScreen } from './View/Tracking/WorkoutLog/ActivityLogListScreen';
import { AnalyticsIndexScreen } from './View/Analytics/AnalyticsIndexScreen';
import { ExerciseListScreen } from './View/MetaData/Exercise/ExerciseListScreen/ExerciseListScreen';
import { ExerciseViewScreen } from './View/MetaData/Exercise/View/ExerciseViewScreen';
import { NoMatch } from './NoMatch';
import { OAuthClientListScreen } from './View/Security/OAuth/OAuthClientListScreen';
import { AthleteListScreen } from './View/Athlete/AthleteListScreen';
import { AthleteViewScreen } from './View/Athlete/View/AthleteViewScreen';
import { OAuthClientViewScreen } from './View/Security/OAuth/View';
import { EngagementRouter } from './View/Engagement/EngagementRouter';
import { DynamicLinkBuilderScreen } from './View/Tools/DynamicLinkBuilderScreen';
import { FileStorageScreen } from './View/CustomerSuccess/FileStorage/FileStorageScreen';
import { FolderViewScreen } from './View/CustomerSuccess/FileStorage/FolderViewScreen';
import { CampaignCalendarIndexScreen } from './View/CustomerSuccess/CampaignCalendar/CampaignCalendarIndexScreen';
import { QrCodeBuilderScreen } from './View/Tools/QrCodeBuilderScreen';
import { DeeplToolScreen } from './View/Tools/DeeplToolScreen';
import { CompanyDashboardScreen } from './View/Analytics/CompanyDashboard/CompanyDashboardScreen';
import { StudioAnalyticsScreen } from './View/Analytics/StudioAnalytics/StudioAnalyticsScreen';
import { useAuth0 } from '@auth0/auth0-react';

export type RootProps = {};

export const Root: React.FC<RootProps> = observer(() => {
  const [loading, setLoading] = useState(true);
  const { authentication } = useRootStore();
  const location = useLocation();
  const history = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const checkRedirect = React.useCallback(() => {
    const isAuthPage = location.pathname.startsWith('/auth');
    console.log('checkRedirect', location.pathname, isAuthPage);
    return getAccessTokenSilently({
      authorizationParams: {
        audience: 'https://api.kinastic.com',
        scope: 'offline_access openid profile email',
        redirect_uri: window.location.origin + '/auth',
      },
    })
      .then((token) => authentication.storeAccessToken(token))
      .then((athlete) => {
        const isAdmin = authentication.isAdmin;
        const path =
          '/auth' === location.pathname
            ? '/'
            : location.pathname.startsWith('/access-denied')
              ? isAdmin
                ? '/'
                : '/auth'
              : location.pathname;
        console.log('checkRedirect', location.pathname, isAuthPage, isAdmin, path);

        if (isAuthPage || location.pathname.startsWith('/access-denied')) {
          console.log(`checkRedirect redirecting to ${path}`);
          history(path, { replace: true });
        } else if (!isAdmin) {
          console.log('checkRedirect redirecting to /access-denied');
          history('/access-denied', { replace: true });
        }
      })
      .catch((err) => {
        console.log(`checkRedirect redirecting to /auth`);
        !isAuthPage && history(`/auth`, { replace: true });
      });
  }, [authentication, getAccessTokenSilently, history, location.pathname]);

  useEffect(() => {
    checkRedirect().finally(() => setLoading(false));
  }, [checkRedirect]);

  if (loading) {
    return <LoadingContainer text={'Loading Page...'} />;
  }

  return (
    <Routes>
      <Route path="/">
        <Route path="auth" element={<AuthScreen />} />
        <Route element={<MainLayout />}>
          <Route index element={<DashboardScreen />} />
          <Route path="infrastructure">
            <Route path="gym">
              <Route index element={<GymListScreen />} />
              <Route path=":gymId/*">
                <Route path="*" element={<GymViewScreen />} />
              </Route>
            </Route>
            <Route path="customers">
              <Route index element={<CustomersListScreen />} />
            </Route>
          </Route>

          <Route path="athlete/*">
            <Route index element={<AthleteListScreen />} />
            <Route path=":athleteId/*" element={<AthleteViewScreen />} />
          </Route>
          <Route path="metadata/*" element={<MetaDataRouter />} />
          <Route path="engagement/*" element={<EngagementRouter />} />
          <Route path="coach/*" element={<CoachRouter />} />
          <Route path="tracking">
            <Route path="activity-log">
              <Route index element={<ActivityLogListScreen />} />
            </Route>
          </Route>
          <Route path="analytics/*">
            <Route index element={<AnalyticsIndexScreen />} />
            <Route path="company-dashboard" element={<CompanyDashboardScreen />} />
            <Route path="studio-analytics" element={<StudioAnalyticsScreen />} />
          </Route>
          {authentication.isAdmin && (
            <Route path="customer-success/*">
              <Route path="campaign-calendar/*" element={<CampaignCalendarIndexScreen />} />
              <Route path="file-storage">
                <Route index element={<FileStorageScreen />} />
                <Route path={'folder/:folderId'} element={<FolderViewScreen />} />
              </Route>
            </Route>
          )}
          {authentication.isOneAboveAll && (
            <Route path="security/*">
              <Route path="oauth">
                <Route index element={<OAuthClientListScreen />} />
                <Route path=":clientId" element={<OAuthClientViewScreen />} />
              </Route>
            </Route>
          )}
          <Route path="tools">
            <Route path="dynamic-link-builder" element={<DynamicLinkBuilderScreen />} />
            <Route path="qr-code-builder" element={<QrCodeBuilderScreen />} />
            <Route path="deepl" element={<DeeplToolScreen />} />
          </Route>
          <Route path="modal">
            <Route path="media">
              <Route index element={<ExerciseListScreen />} />
              <Route path=":exerciseId" element={<ExerciseViewScreen />} />
            </Route>
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Route>
    </Routes>
  );
});
